import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './App.css';

function App() {
  const [activeSection, setActiveSection] = useState('home');
  const [copySuccess, setCopySuccess] = useState(false);

  const pageTransition = {
    initial: { 
      opacity: 0,
      y: 20,
      scale: 0.98
    },
    animate: { 
      opacity: 1,
      y: 0,
      scale: 1
    },
    exit: { 
      opacity: 0,
      y: -20,
      scale: 0.98
    },
    transition: { 
      duration: 0.4,
      ease: [0.645, 0.045, 0.355, 1]
    }
  };

  const containerVariants = {
    initial: { opacity: 0 },
    animate: { 
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    },
    exit: { opacity: 0 }
  };

  const itemVariants = {
    initial: { 
      opacity: 0,
      y: 20
    },
    animate: { 
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        ease: [0.645, 0.045, 0.355, 1]
      }
    },
    exit: { 
      opacity: 0,
      y: -20
    }
  };

  const handleCopyCA = async () => {
    try {
      await navigator.clipboard.writeText("0x181112f29410d729faEe8553654BFe0b82DD6878");
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const homeStyle = {
    backgroundImage: `url(/img/back.png)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <div className="min-h-screen h-screen bg-gradient-to-br from-purple-600 via-pink-500 to-yellow-400 flex items-center justify-center relative overflow-hidden">
      {/* Video Background */}
      <video
        autoPlay
        loop
        muted
        playsInline
        className="fixed inset-0 w-[130%] h-[130%] object-cover z-0 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      >
        <source src="/img/animation.mp4" type="video/mp4" />
      </video>
      
      {/* Main content */}
      <div className="w-[90vw] h-[90vh] relative z-10">
        <motion.div 
          className="bg-black/80 backdrop-blur-md rounded-2xl shadow-2xl p-8 w-full h-full border border-purple-400/50 relative overflow-hidden hover:border-purple-300/70 transition-all duration-300"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {/* Navigation menu */}
          <div className="absolute top-4 right-6 z-10">
            <motion.div 
              className="flex space-x-6"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`skew-button px-6 py-2 text-white rounded transition-colors ${
                  activeSection === 'home' 
                  ? 'bg-purple-600' 
                  : 'bg-purple-500/20 hover:bg-purple-500/40'
                }`}
                onClick={() => setActiveSection('home')}
              >
                <span className="counter-skew">Home</span>
              </motion.button>
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`skew-button px-6 py-2 text-white rounded transition-colors ${
                  activeSection === 'about' 
                  ? 'bg-purple-600' 
                  : 'bg-purple-500/20 hover:bg-purple-500/40'
                }`}
                onClick={() => setActiveSection('about')}
              >
                <span className="counter-skew">About</span>
              </motion.button>
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`skew-button px-6 py-2 text-white rounded transition-colors ${
                  activeSection === 'tokenomics' 
                  ? 'bg-purple-600' 
                  : 'bg-purple-500/20 hover:bg-purple-500/40'
                }`}
                onClick={() => setActiveSection('tokenomics')}
              >
                <span className="counter-skew">Tokenomics</span>
              </motion.button>
            </motion.div>
          </div>

          {/* Content sections */}
          <div className="relative h-full overflow-y-auto custom-scrollbar">
            <AnimatePresence mode="wait">
              {activeSection === 'home' && (
                <motion.div 
                  key="home"
                  className="h-full flex flex-col items-center justify-between"
                  variants={containerVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                >
                  <div className="flex-grow"></div>
                  <motion.div 
                    className="flex max-sm:flex-col items-center justify-center max-sm:gap-4 gap-12"
                    variants={itemVariants}
                  >
                    <motion.img 
                      src="/img/jetp.gif"
                      alt="Jet"
                      className="max-sm:w-[200px] max-sm:h-[200px] w-[500px] h-[500px] object-contain"
                      initial={{ scale: 0.8, opacity: 0, x: -100 }}
                      animate={{ scale: 1, opacity: 1, x: 0 }}
                      transition={{ duration: 0.5 }}
                    />
                    <div className="flex flex-col items-center">
                      <motion.h1 
                        className="max-sm:text-5xl text-8xl font-bold text-white max-sm:text-center"
                        variants={itemVariants}
                      >
                        POLMOON
                      </motion.h1>
                      <motion.div 
                        className="typing-animation max-sm:text-lg text-2xl text-gray-300 font-medium max-sm:text-center max-sm:px-4"
                        variants={itemVariants}
                      >
                        The memecoin that will make Polygon Great Again! 🚀
                      </motion.div>
                      <div className="flex max-sm:flex-col items-center justify-center max-sm:gap-4">
                        <motion.img 
                          src="/img/laser.gif"
                          alt="Laser Animation"
                          className="max-sm:w-[150px] max-sm:h-[150px] w-64 h-64 object-contain"
                          initial={{ scale: 0.8, opacity: 0 }}
                          animate={{ scale: 1, opacity: 1 }}
                          transition={{ duration: 0.5 }}
                        />
                        <motion.div 
                          className="flex flex-col gap-2 ml-4 max-sm:ml-0"
                          variants={itemVariants}
                        >
                          <motion.a
                            href="https://x.com/Polmoon_LFG"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-purple-600 hover:bg-purple-700 text-white max-sm:px-6 px-8 max-sm:py-2 py-3 rounded-lg flex items-center justify-center gap-2 transition-colors max-sm:text-sm"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                          >
                            Twitter
                          </motion.a>
                          <motion.a
                            href="https://t.me/polmoon_portal"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-purple-600 hover:bg-purple-700 text-white max-sm:px-6 px-8 max-sm:py-2 py-3 rounded-lg flex items-center justify-center gap-2 transition-colors mt-2 max-sm:text-sm"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                          >
                            Telegram
                          </motion.a>
                        </motion.div>
                      </div>
                    </div>
                  </motion.div>
                  <div className="flex-grow"></div>

                  <motion.div 
                    className="text-gray-400 max-sm:text-xs text-sm flex flex-col justify-center items-center gap-8 mb-12"
                    variants={itemVariants}
                  >
                    <motion.div 
                      className="flex gap-8 items-center mb-4"
                      variants={itemVariants}
                    >
                      <motion.a
                        href="https://dexscreener.com/polygon/0x181112f29410d729faEe8553654BFe0b82DD6878"
                        target="_blank"
                        rel="noopener noreferrer"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <img 
                          src="/img/dexscreener.png" 
                          alt="Dexscreener" 
                          className="w-16 h-16"
                        />
                      </motion.a>
                      <motion.a
                        href="https://www.dextools.io/app/en/polygon/pair-explorer/0x181112f29410d729faEe8553654BFe0b82DD6878"
                        target="_blank"
                        rel="noopener noreferrer"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <img 
                          src="/img/dextools.svg" 
                          alt="Dextools" 
                          className="w-16 h-16"
                        />
                      </motion.a>
                    </motion.div>
                    <div className="flex max-sm:flex-col items-center gap-6">
                      <div className="flex items-center gap-4">
                        <span className="w-2 h-2 bg-green-500 rounded-full animate-pulse"></span>
                        Live on Polygon
                      </div>
                      <div className="max-sm:hidden">•</div>
                      <div className="flex items-center gap-4">
                        <span className="text-purple-500">🔒</span>
                        Contract Verified
                      </div>
                    </div>
                  </motion.div>
                </motion.div>
              )}

              {activeSection === 'about' && (
                <motion.div 
                  key="about"
                  className="space-y-8 relative"
                  variants={containerVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                >
                  {/* Beer gif à gauche */}
                  <motion.img 
                    src="/img/beer2.gif"
                    alt="Beer"
                    className="fixed bottom-0 left-0 max-sm:w-[150px] max-sm:h-[150px] w-[500px] h-[500px] object-contain z-50"
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0 }}
                    transition={{ 
                      duration: 0.5,
                      delay: 0.2,
                      type: "spring",
                      stiffness: 260,
                      damping: 20
                    }}
                  />
                  {/* GM_4 image à droite */}
                  <motion.img 
                    src="/img/GM_4.gif"
                    alt="GM"
                    className="fixed bottom-0 right-0 max-sm:w-[150px] max-sm:h-[150px] w-[500px] h-[500px] object-contain z-50"
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0 }}
                    transition={{ 
                      duration: 0.5,
                      delay: 0.2,
                      type: "spring",
                      stiffness: 260,
                      damping: 20
                    }}
                  />
                  <motion.div 
                    className="bg-black/40 backdrop-blur-sm p-6 rounded-lg"
                    variants={itemVariants}
                  >
                    <motion.h1 
                      className="text-3xl font-bold text-center mb-4 text-white"
                      variants={itemVariants}
                    >
                      About POLMOON
                    </motion.h1>
                    <motion.div 
                      className="max-w-2xl mx-auto space-y-4"
                      variants={itemVariants}
                    >
                      <motion.div 
                        className="bg-purple-500/10 p-6 rounded-lg border border-purple-500/20"
                        variants={itemVariants}
                      >
                        <p className="text-gray-300 text-base leading-relaxed">
                          The Polmoon team is convinced that Polygon is much bigger than the capitalization that the crypto market currently gives it. We believe that adding more good memecoins will help bring Polygon back to the forefront. If you're also bullish on $POL, then join us now and help us to Make Polygon Great Again and bring back more fun into its ecosystem with $PMOON 🟣
                        </p>
                      </motion.div>
                      <motion.div 
                        className="bg-purple-500/10 p-6 rounded-lg border border-purple-500/20"
                        variants={itemVariants}
                      >
                        <div className="flex flex-col items-center space-y-3">
                          <motion.h2 
                            className="text-xl font-semibold mb-4 text-purple-300"
                            variants={itemVariants}
                          >
                            Contract Address
                          </motion.h2>
                          <motion.div 
                            className="w-full bg-black/20 p-3 rounded-lg"
                            variants={itemVariants}
                          >
                            <p className="text-gray-300 break-all text-center font-mono text-sm">
                              0x181112f29410d729faEe8553654BFe0b82DD6878
                            </p>
                          </motion.div>
                          <motion.button 
                            onClick={handleCopyCA}
                            className="bg-purple-500 hover:bg-purple-600 text-white px-6 py-2 rounded-lg flex items-center gap-2 transition-colors font-semibold text-sm"
                            variants={itemVariants}
                          >
                            {copySuccess ? '✓ Copied!' : '📋 Copy Address'}
                          </motion.button>
                        </div>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </motion.div>
              )}

              {activeSection === 'tokenomics' && (
                <motion.div 
                  key="tokenomics"
                  className="space-y-8 relative flex flex-col items-center"
                  variants={containerVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                >
                  <motion.img 
                    src="/img/rocket.gif"
                    alt="Rocket"
                    className="max-sm:w-32 max-sm:h-32 w-64 h-64 object-contain mb-4"
                    initial={{ opacity: 0, y: -100 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -100 }}
                    transition={{ 
                      duration: 0.5,
                      type: "spring",
                      stiffness: 260,
                      damping: 20
                    }}
                  />
                  {/* Bottom images */}
                  <motion.img 
                    src="/img/pump.gif"
                    alt="Left decoration"
                    className="absolute bottom-0 left-0 max-sm:w-[100px] max-sm:h-[100px] w-[400px] h-[400px] object-contain"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ 
                      duration: 0.5,
                      delay: 0.3,
                      type: "spring",
                      stiffness: 100
                    }}
                  />
                  <motion.img 
                    src="/img/3png.png"
                    alt="Right decoration"
                    className="absolute -bottom-20 right-0 max-sm:w-[100px] max-sm:h-[100px] w-[600px] h-[600px] object-contain"
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ 
                      duration: 0.5,
                      delay: 0.3,
                      type: "spring",
                      stiffness: 100
                    }}
                  />
                  <motion.div 
                    className="w-full max-w-2xl"
                    variants={itemVariants}
                  >
                    <motion.h1 
                      className="text-3xl font-bold text-center mb-6 text-white"
                      variants={itemVariants}
                    >
                      Tokenomics
                    </motion.h1>
                    <motion.div 
                      className="flex justify-center items-center"
                      variants={itemVariants}
                    >
                      <motion.div 
                        className="w-full max-w-md"
                        variants={itemVariants}
                      >
                       
                        <motion.ul 
                          className="space-y-3 text-gray-300"
                          variants={itemVariants}
                        >
                          <motion.li variants={itemVariants} className="text-center">
                            • LIQUIDITY POOL: 65%
                          </motion.li>
                          <motion.li variants={itemVariants} className="text-center">
                            • AIRDROP: 10%
                          </motion.li>
                          <motion.li variants={itemVariants} className="text-center">
                            • MARKETING: 7.5%
                          </motion.li>
                          <motion.li variants={itemVariants} className="text-center">
                            • TEAM: 7.5%
                          </motion.li>
                          <motion.li variants={itemVariants} className="text-center flex flex-col items-center gap-2">
                            • BURN: 10%
                            <motion.a
                              href="https://polygonscan.com/token/0x181112f29410d729faee8553654bfe0b82dd6878?a=0x000000000000000000000000000000000000dead"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bg-purple-600 hover:bg-purple-700 text-white px-3 py-1 rounded-md text-sm transition-colors"
                              whileHover={{ scale: 1.05 }}
                              whileTap={{ scale: 0.95 }}
                            >
                              View on PolygonScan
                            </motion.a>
                          </motion.li>
                          <motion.li variants={itemVariants} className="text-center">
                            • REFLECTION: 2%
                          </motion.li>
                        </motion.ul>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default App;
